export const environment = {
  production: true,
  env: 'production',
  API_END_POINT: 'https://cdn.tinyview.com',
  IMAGE_END_POINT: 'https://assets.tinyview.com',
  API_BASE_URL: 'https://api.tinyview.com/v1/api',
  FIREBASE_API_ENDPOINT: 'https://us-central1-tinyview-d78fb.cloudfunctions.net',
  OLD_API_END_POINT: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com',
  OLD_IMAGE_END_POINT: 'https://storage.googleapis.com/tinyview-d78fb-images',
  CDN_BASE_URL: 'https://cdn.tinyview.com/',
  config: {
    apiKey: 'AIzaSyCiaYKv2KPZLDbeVDxcubNqowc7HXMatIg',
    authDomain: 'tinyview-d78fb.firebaseapp.com',
    databaseURL: 'https://tinyview-d78fb.firebaseio.com',
    projectId: 'tinyview-d78fb',
    storageBucket: 'tinyview-d78fb.appspot.com',
    messagingSenderId: '156156699881',
    appId: '1:156156699881:web:69fa33d26b0b4dc9e53c78',
    measurementId: 'G-4X25DZBGKB'
  },
  BRANCH_KEY: 'key_live_jbPTddmEZ71QQJo1ogw4mnecqscKHaiG',
  SWITCH_APP_LINK: 'com.newput.tinyview',
  DOMAIN_NAME: 'https://tinyview.com',
  EMAIL_LINK_DATA: {
    url: 'https://social.tinyview.com/email-link-login',
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.newput.tinyview'
    },
    android: {
      packageName: 'com.newput.tinyview',
      installApp: false
    },
    dynamicLinkDomain: 'tinyviewmain.page.link'
  }
};
